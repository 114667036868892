import { bffServiceInstance } from "@/plugins/api_service/services";
import { state } from "@/socket";

export default {
  getImportExportAccountCmsComponent: () =>
    bffServiceInstance.get(`/management/component-csv-setting`, {}),
  storeImportExportAccountCmsComponent: (data) =>
    bffServiceInstance.put(`/management/component-csv-setting`, {}, data),
  getListServiceType: (csv_type) =>
    bffServiceInstance.get(
      `/management/component-csv-settings/get-list-service-type`,
      { csv_type }
    ),
  getListHeaders: ({ csv_type, module_type, cms_code }) =>
    bffServiceInstance.get(
      `/management/component-csv-settings/get-list-headers`,
      { csv_type: csv_type, module_type: module_type, cms_code: cms_code }
    ),
  updateSettingCSV: ({
    csv_type,
    module_type,
    cms_code,
    output_headers,
    output_deleted,
  }) =>
    bffServiceInstance.put(
      `/management/component-csv-settings/update`,
      {},
      { csv_type, module_type, cms_code, output_headers, output_deleted }
    ),
  getInfoByPageCode: (query) =>
    bffServiceInstance.get(`/management/language-settings`, query),
  getPageCodeSelectlist: () =>
    bffServiceInstance.get(`/management/get-page-select-list`, {}),
  updateLanguageSetting: (data) =>
    bffServiceInstance.post(`/management/language-settings`, {}, data),
  getThemeSetting: () =>
    bffServiceInstance.get(`/management/theme_settings`, {}),
  updateThemeSetting: (body) =>
    bffServiceInstance.post(`/management/theme_settings`, {}, body),
  exportCsv: (socketEventName, query) =>
    bffServiceInstance.post(
      `/management/invoice-csv/export`,
      {},
      {
        socket_event_name: socketEventName,
        socket_id: state.socketId,
        ...query,
      }
    ),
};
