import { SET_PROCESS_EXPORT_CSV_FILE } from "./types";

export default {
  [SET_PROCESS_EXPORT_CSV_FILE]: (state, payload) => {
    state.exportCsv = {
      ...state.exportCsv,
      ...payload,
    };
  },
};
