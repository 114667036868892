import {
  ACT_EXPORT_CSV_MANAGE_INVOICE,
  SET_PROCESS_EXPORT_CSV_FILE,
  ACT_FORCE_FRESH_IMPORT_CSV,
  SET_REFRESH_IMPORT_CSV_FILE,
} from "./types";
import { autoDownloadFromUrl } from "@/helpers/common";
import managementSettingService from "@/services/manage/setting";
import i18n from "@/plugins/i18n";

export default {
  [ACT_EXPORT_CSV_MANAGE_INVOICE]: async ({ commit }, { vm, querySearch }) => {
    commit(SET_PROCESS_EXPORT_CSV_FILE, { loading: true, error: false });
    try {
      let response = await managementSettingService.exportCsv(querySearch);
      let { url_download } = response;
      autoDownloadFromUrl(url_download);
      commit(SET_PROCESS_EXPORT_CSV_FILE, { loading: false, error: false });
      vm.$toast.success(i18n.t("setting.label_download_csv_successfully"));
    } catch (e) {
      commit(SET_PROCESS_EXPORT_CSV_FILE, { loading: false, error: true });
      vm.$toast.error(i18n.t("panel.fail"));
    }
  },
  [ACT_FORCE_FRESH_IMPORT_CSV]: ({ commit }) => {
    commit(SET_REFRESH_IMPORT_CSV_FILE);
  },
};
